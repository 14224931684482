import React from 'react'
import RTGLink from '@shared/link'
import './redeem-message.sass'

const RedeemMessage = () => (
  <div className="redeem-message">
    <span>
      Redeem your Gift Card
      <RTGLink
        className="small-12"
        aria-describedby="redeem-gift-card-online"
        data={{
          slug: '/',
          category: 'gift card',
          action: 'click',
          label: 'online',
          text: ' online ',
        }}
      />
      or in your nearest
      <RTGLink
        className="small-12 period"
        aria-describedby="redeem-gift-card-in-showroom"
        data={{
          slug: '/stores',
          category: 'gift card',
          action: 'click',
          label: 'showroom',
          text: ' showroom',
        }}
      />
      .
    </span>
  </div>
)

export default RedeemMessage
