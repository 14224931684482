const axios = require('axios')
// import { request, HttpError } from '@helpers/general'

// eslint-disable-next-line import/prefer-default-export
export const balanceInquiry = (cardNumber, pin, token) =>
  axios
    .get(`${process.env.GATSBY_GC_SERVICE_URL}/balanceInquiry?cardNumber=${cardNumber}&pin=${pin}`, {
      headers: { authtoken: token },
    })
    .then(response => response.data)
