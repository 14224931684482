import { useEffect, useState } from 'react'

const useRecaptchaLoader = () => {
  const [isWindow, setIsWindow] = useState(false)
  const [isScriptLoaded, setIsScriptLoaded] = useState(false)

  const scriptSrc = 'https://www.google.com/recaptcha/api.js'

  useEffect(() => {
    setIsWindow(true)

    if (window.grecaptcha) {
      setIsScriptLoaded(true)
    } else {
      const handleScriptLoad = () => setIsScriptLoaded(true)
      window.addEventListener('grecaptchaLoaded', handleScriptLoad)

      return () => window.removeEventListener('grecaptchaLoaded', handleScriptLoad)
    }

    return () => setIsWindow(false)
  }, [])

  useEffect(() => {
    const script = document.createElement('script')
    script.src = scriptSrc
    script.defer = true
    script.async = true
    script.onload = () => {
      const event = new Event('grecaptchaLoaded')
      window.dispatchEvent(event)
    }
    document.head.appendChild(script)

    return () => {
      document.head.removeChild(script)
    }
  }, [scriptSrc])

  return { isWindow, isScriptLoaded }
}

export default useRecaptchaLoader
