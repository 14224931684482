import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  useMediaQuery,
  makeStyles,
  Grid,
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core'
import classNames from 'classnames'
import StrapiMarkdown from './Markdown'

const useStyles = makeStyles(theme => ({
  accordion: {
    backgroundColor: 'transparent',
    outline: 'none !important',
    '& *': {
      outline: 'none !important',
    },
    '& .MuiSvgIcon-root': {
      fill: 'black',
    },
  },
  borderNone: {
    borderBottom: 'solid 1px transparent',
    transition: 'border-bottom .3s ease',
    '&:hover': {
      // borderBottom: `solid 1px ${theme.palette.primary.main}`,
    },
    '&:before': {
      backgroundColor: 'transparent',
    },
  },
  summary: {
    position: 'relative',
    borderTop: '.0625rem solid #dedede',
    borderBottom: '.0625rem solid #dedede',
    margin: '.5em 0',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
    textTransform: 'uppercase',
    '& h2': {
      color: '#333',
      fontSize: '1rem',
      fontWeight: '600',
      cursor: 'pointer',
      textTransform: 'uppercase',
      clear: 'both',
    },
  },
  desktopSummary: ({ isFAQ }) =>
    isFAQ
      ? {
          '&:before': {
            content: '"•"',
            position: 'absolute',
            left: 2,
            top: '50%',
            transform: 'translateY(-50%)',
            fontSize: '1.25rem',
          },
        }
      : {},
  expandedSummary: {
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  expandedContent: {
    borderBottom: `solid 1px ${theme.palette.primary.main}`,
  },
  content: {
    display: 'block',
    padding: theme.spacing(2),
    paddingTop: '0px !important',
    [theme.breakpoints.down('md')]: {
      padding: '1.25rem 0',
    },
    '& p': { margin: '1rem 0' },
  },
}))

const Accordion = ({ data: accordion, children = null, gridWrapper = true, isFAQ = false, className }) => {
  const classes = useStyles({ isFAQ })
  const [expanded, setExpanded] = useState(false)
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'))
  const { id, Heading, Markdown, Hidden } = accordion?.Accordion || accordion || {}
  const customProps = {
    elevation: 0,
    square: true,
    defaultExpanded: !Hidden,
  }

  const onKeyDown = useCallback(
    event => {
      const code = event.keyCode || event.which
      if (code === 13 || code === 32) {
        setExpanded(!expanded)
      }
      if (code === 27) {
        setExpanded(false)
      }
    },
    [expanded],
  )

  const Component = (
    <MuiAccordion
      className={classNames(classes.accordion, { [classes.borderNone]: !expanded }, className)}
      {...customProps}
    >
      <AccordionSummary
        className={classNames(classes.summary, {
          [classes.expandedSummary]: expanded,
          [classes.desktopSummary]: isDesktop,
        })}
        onKeyDown={onKeyDown}
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${id}-content`}
        id={`${id}-header`}
      >
        <h2 className={classes.h2}>{Heading}</h2>
      </AccordionSummary>
      <AccordionDetails className={classNames(classes.content, { [classes.expandedContent]: expanded })}>
        {children || <StrapiMarkdown gridWrapper={false} data={{ Markdown }} />}
      </AccordionDetails>
    </MuiAccordion>
  )

  return gridWrapper ? (
    <Grid item md={12}>
      {Component}
    </Grid>
  ) : (
    Component
  )
}

export default Accordion

Accordion.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.node,
  gridWrapper: PropTypes.bool,
  isFAQ: PropTypes.bool,
  className: PropTypes.string,
}

export const StrapiPageAccordionFragment = graphql`
  fragment StrapiPageAccordionFragment on StrapiAccordion {
    id
    Heading
    Hidden
    Markdown {
      data {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
