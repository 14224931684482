import React, { useState, useEffect } from 'react'
import AddToCartModal from '@components/product/product-parts/add-to-cart-modal'
import giftCardImage from '@assets/images/gift-cards.jpg'
import { Slider, styled } from '@mui/material'
import './gift-card-purchase.sass'
import { getCustomGiftCardProductData } from '@helpers/product'
import { setupAnalytics } from '@helpers/google-tag-manager'
import GiftCardAccordions from '../accordions'

const defaultValue = 25

const marks = [
  { value: 1 },
  { value: 10 },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50 },
  { value: 60 },
  { value: 70 },
  { value: 80 },
]

const StyledSlider = styled(Slider)(() => ({
  '& .MuiSlider-markLabel': {
    fontSize: '14px',
    lineHeight: '18.48px',
    color: '#333',
    fontWeight: '400',
    marginTop: '2px',
  },
  '& .MuiSlider-thumb::before': {
    boxShadow: 'none',
  },
}))

const GiftCards = () => {
  const [quantity, setQuantity] = useState(1)
  const [giftCard, setGiftCard] = useState(getCustomGiftCardProductData(1))
  const [modalOpen, setModalOpen] = useState(false)

  const handleChange = (_event, newValue) => {
    if (typeof newValue === 'number') {
      setQuantity(newValue)
    }
  }

  const showModal = () => {
    setModalOpen(true)
  }
  const closeModal = () => {
    if (modalOpen) {
      setModalOpen(false)
    }
  }

  useEffect(() => {
    setGiftCard(prev => ({
      ...prev,
      price: defaultValue * quantity,
      unitPrice: 25 * quantity,
      pricing: { default_price: 25 * quantity },
    }))
  }, [quantity])

  useEffect(() => {
    setupAnalytics({ pageData: { type: 'gift-card', title: 'Gift Card Purchase', path: '/gift-card/purchase' } })
  }, [])

  return (
    <div className="gc-page card">
      <div>
        <h1 className="gc-header ">ROOMS TO GO GIFT CARDS</h1>
      </div>
      <div className="gc-wrapper grid-x">
        <div className="gc-content-wrapper grid-x small-12 large-4">
          <div className="gift-card-tile">
            <div className="gc-image">
              <img src={giftCardImage} alt="gift card" />
            </div>
            <p className="gc-name">
              <sup>$</sup>
              {defaultValue} - <sup>$</sup>2000 Gift Cards
            </p>
            <p className="gc-description">Please select a gift card amount by dragging the slider:</p>
            <p id="gift-card-quantity-slider" className="gc-label">
              <sup>$</sup>
              {quantity * defaultValue}
            </p>
            <div className="gc-slider-wrapper">
              <StyledSlider
                value={quantity}
                min={1}
                max={80}
                marks={marks}
                onChange={handleChange}
                aria-labelledby="gift-card-quantity-slider"
              />
              <div className="gc-slider-marks-wrapper">
                <p className="gc-slider-mark">$25</p>
                <p className="gc-slider-mark">$2000</p>
              </div>
            </div>
            <div className="gc-add-to-cart-wrapper">
              <button type="button" className="blue-action-btn" onClick={showModal}>
                Add to cart
              </button>
            </div>
          </div>
        </div>
        <div className="small-12 large-7 grid-x cell gc-right">
          <p>
            The Rooms To Go gift card is an ideal gift choice for weddings, graduations, anniversaries and so many other
            special occasions.
          </p>
          <p>You will receive your Gift Card via certified mail, with no shipping or handling cost to you!</p>
          <p>
            NOTE: For security purposes, Gift Cards will only be mailed to purchaser's billing address in the Contiguous
            U.S.
          </p>
          <div className="small-12">
            <GiftCardAccordions />
          </div>
        </div>
        {modalOpen && (
          <AddToCartModal
            modalOpen={modalOpen}
            product={giftCard}
            price={giftCard.price}
            closeModal={closeModal}
            index={0}
          />
        )}
      </div>
    </div>
  )
}

export default GiftCards
