import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import GiftCards from '@components/gift-cards/purchase'
import Layout from '../../components/layout'

const Purchase = ({ props }) => (
  <Layout {...props}>
    <Helmet title="Buy Rooms To Go Gift Cards - Redeem Gift Card Online" />
    <GiftCards />
  </Layout>
)

Purchase.propTypes = {
  props: PropTypes.any,
}

export default Purchase
